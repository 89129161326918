import service from '@/utils/request'; // @Tags SubscriptionServerCategory
// @Summary 创建SubscriptionServerCategory
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SubscriptionServerCategory true "创建SubscriptionServerCategory"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /subscriptionServerCategory/createSubscriptionServerCategory [post]

export var createSubscriptionServerCategory = function createSubscriptionServerCategory(data) {
  return service({
    url: "/subscriptionServerCategory/createSubscriptionServerCategory",
    method: 'post',
    data: data
  });
}; // @Tags SubscriptionServerCategory
// @Summary 删除SubscriptionServerCategory
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SubscriptionServerCategory true "删除SubscriptionServerCategory"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /subscriptionServerCategory/deleteSubscriptionServerCategory [delete]

export var deleteSubscriptionServerCategory = function deleteSubscriptionServerCategory(data) {
  return service({
    url: "/subscriptionServerCategory/deleteSubscriptionServerCategory",
    method: 'delete',
    data: data
  });
}; // @Tags SubscriptionServerCategory
// @Summary 删除SubscriptionServerCategory
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除SubscriptionServerCategory"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /subscriptionServerCategory/deleteSubscriptionServerCategory [delete]

export var deleteSubscriptionServerCategoryByIds = function deleteSubscriptionServerCategoryByIds(data) {
  return service({
    url: "/subscriptionServerCategory/deleteSubscriptionServerCategoryByIds",
    method: 'delete',
    data: data
  });
}; // @Tags SubscriptionServerCategory
// @Summary 更新SubscriptionServerCategory
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SubscriptionServerCategory true "更新SubscriptionServerCategory"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /subscriptionServerCategory/updateSubscriptionServerCategory [put]

export var updateSubscriptionServerCategory = function updateSubscriptionServerCategory(data) {
  return service({
    url: "/subscriptionServerCategory/updateSubscriptionServerCategory",
    method: 'put',
    data: data
  });
}; // @Tags SubscriptionServerCategory
// @Summary 用id查询SubscriptionServerCategory
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SubscriptionServerCategory true "用id查询SubscriptionServerCategory"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /subscriptionServerCategory/findSubscriptionServerCategory [get]

export var findSubscriptionServerCategory = function findSubscriptionServerCategory(params) {
  return service({
    url: "/subscriptionServerCategory/findSubscriptionServerCategory",
    method: 'get',
    params: params
  });
}; // @Tags SubscriptionServerCategory
// @Summary 分页获取SubscriptionServerCategory列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取SubscriptionServerCategory列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /subscriptionServerCategory/getSubscriptionServerCategoryList [get]

export var getSubscriptionServerCategoryList = function getSubscriptionServerCategoryList(params) {
  return service({
    url: "/subscriptionServerCategory/getSubscriptionServerCategoryList",
    method: 'get',
    params: params
  });
};